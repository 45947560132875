import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule),
    canActivate: [AuthGuard], // Protect the home page with the authGuard
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard], // Protect the home page with the authGuard

  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'allhistory',
    loadChildren: () => import('./allhistory/allhistory.module').then( m => m.AllhistoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'modelinferencing',
    loadChildren: () => import('./modelinferencing/modelinferencing.module').then( m => m.ModelinferencingPageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'addtext',
    loadChildren: () => import('./addtext/addtext.module').then( m => m.AddtextPageModule),
    canActivate: [AuthGuard],
  },
  
  { path: '', pathMatch: 'full' , redirectTo: localStorage.getItem('authenticationKey') ? 'menu' : '**'},

  
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  
  { path: '**', pathMatch: 'full', redirectTo: localStorage.getItem('authenticationKey') ? 'menu' : 'login', },
  
  
  
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
